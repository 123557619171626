$body-bg: #27293d;
$body-bg-2: #303247;
$body-bg-3: #3b3d55;
$body-color-primary: #46bba0;
$body-color: #fff;
$body-color-darker: #b0b3c7;
$body-color-disabled: #9c9fb3;

$theme-colors: (
  "primary": $body-color-primary,
  "secondary": $body-bg-2,
);