@import "../variables.scss";

.avatar {
  display: block;
  background-color: $body-bg-3;
  color: $body-color-darker;
  text-align: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;

  &.avatar-lg {
    width: 5rem;
    height: 5rem;
  }

  &.avatar-sm {
    font-size: 1.7rem;
    width: 3.6rem;
    height: 3.6rem;
  }

  > img {
    width: 100%;
    border-radius: 50%;
  }
}

.avatar-ribbon {
  position: absolute;
  background-color: $body-color-primary;
  width: 1.7rem;
  height: 1.7rem;
  padding-top: .2rem;
  padding-left: .1rem;
  font-size: .75rem;
  border-radius: 50%;
  text-align: center;
  right: 0;
  top: 0;
}
