@import "../variables.scss";
@import "../mixins.scss";

.step {
  margin-top: 2rem;
}

.step.disabled {
  color: $body-color-disabled;
}

.stepContent {
  padding: 0.5rem 0;
}

.stepContent p {
  margin: 0;
  padding: 0;
}

.stepNumber {
  display: inline-block;
  width: 2.5rem;
}

.stepItem {
  @extend .rounded;

  display: flex;
  padding: 1rem;
  background-color: $body-bg-2;
  color: $body-color;

  &:not(.active) {
    margin: .5rem 0;
    cursor: pointer;

    &:hover {
      background-color: $body-bg-3;
      text-decoration: none;
      color: $body-color;
    }
  }

  &.active {
    background-color: $body-bg-2;
  }

  small {
    color: $body-color-darker;
  }

  .distance {
    font-size: 1.5rem;
    font-weight: bold;
  }

}

.stepNav {
  @extend .rounded;
  display: flex;
  margin: 0.5rem 0;
  background-color: $body-bg-2;

  button {
    font-size: 0.9rem;
  }

  .btn-link, a {
    @extend .rounded;

    color: $body-color-darker;
    display: inline-block;
    text-decoration: none !important;
    padding: .5rem 1rem;

    &.active {
      background-color: $body-bg-3;
      text-decoration: none;
      color: $body-color;
    }

    &:hover {
      text-decoration: none;
      color: $body-color;
    }
  }
}

.stepNavContent {
  @extend .rounded;
  background-color: $body-bg-2;
  padding: 1rem; 
  font-size: .9rem;

  &.priceList {
    width: 100%;

    td {
      padding: .5rem 1rem;
    }

    tr:first-child, {
      td {
        padding-top: 1rem;
      }
    }

    tr:last-child, {
      td {
        padding-bottom: 1rem;
      }
    }


  }
}

.groundGallery {
  display: flex;
  align-items: stretch;
  justify-content: center;

  .image {
    @extend .rounded;

    background-color: $body-bg-2;
    width: 20%;
    height: 4rem;
    margin-right: 2%;
    cursor: pointer;
    opacity: .7;
    background: transparent no-repeat center;
    background-size: cover;

    &:hover {
      opacity: 1;
    }
  }

}

.groundReviews {
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow-wrap: break-word;

  .review {
    @extend .rounded;

    font-size: .9rem;
    flex-grow: 1;
    padding: 1rem;
    width: 0;
    margin-right: .5rem;
    min-height: 4rem;
    background-color: $body-bg-2;

    blockquote {
      padding: 0;
      margin: 0;
    }

    small {
      color: $body-color-darker;
    }
  }
}

.btn-link.groundNext {
  @extend .rounded;

  width: 12%;
  color: $body-color-darker;
  text-align: center;
  background-color: $body-bg-2;

  &:hover {
    background: $body-bg-3;
    color: $body-color;
  }
}

.locationForm {
  margin-top: 1.4rem;
}

.rating {
  cursor: help;
}

.not-active {
  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

@media screen and (min-width: 500px) {
  .stepContent {
    padding: 0.5rem 0 0.5rem 2.5rem;
  }
}