@import 'variables.scss';
@import 'bootstrap.scss';
@import 'autocomplete.scss';

.clickable {
  cursor: pointer;
}

#app {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100vh;
}

#main {
  flex: 100%;
  padding: 2rem;
}

#detail {
  flex: 0%;
  display: none;
  background-color: $body-bg-2;

  &.fullscreen {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    display: block;
    z-index: 50;
  }

  .detail-content {
    padding: 2rem;

    .filled-bg {
      background-color: $body-bg-2; 
    }
  }

  .close-link {
    display: block;
    z-index: 60;
  }
}

#main {
  header {
    margin-bottom: 4rem;
    text-align: center;

    a {
      display: inline-block;
      margin: 0 auto;
      text-decoration: none;
      color: $body-color;
    }

    .title {
      font-size: 2rem;
    }

    .title strong,
    .title svg {
      color: $body-color-primary;
    }

    .btn-menu {
      font-size: 1.3rem;
      margin-top: .1em;
      float: left;
      padding: 0;
      outline: none;
      box-shadow: none;

      &:hover {
        color: $body-color-primary;
      }
    }
  }

  .subtitle {
    margin-bottom: 4rem;
    text-align: center;
  }

  .main-nav {
    a {
      display: block;
      text-decoration: none;
      text-align: center;
      color: $body-color;
      font-size: 1.3rem;
      padding: 0.5rem 0;

      &:hover,
      &.active {
        color: $body-color-primary
      }
    }
  }

  .nav-tabs {
    border-bottom: 2px solid $body-bg-3;
    font-size: 1.1rem;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 3rem;

    .nav-link {
      border: 0;
      border-bottom: 3px solid transparent;
      color: $body-color;
      width: 50%;
      padding: 0 0 2rem 0;
      outline: none;
      box-shadow: none;

      &:hover {
        color: $body-color-primary;
      }

      &.active {
        border-bottom: 3px solid $body-color-primary;
      }

    }
  }
}

@media screen and (min-width: 350px) {
  #main {
    header {
      .title {
        font-size: 2.4rem;
      }

      .btn-menu {
        font-size: 1.55rem;
      }
    }
  }
}

@media screen and (min-width: 410px) {
  #main {
    header {
      .title {
        font-size: 2.8rem;
      }

      .btn-menu {
        font-size: 1.87rem;
      }
    }
  }
}

@media screen and (min-width: 500px) {

  #main {
    header { 
      .title {
        font-size: 3rem;
      }

      .btn-menu {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  #detail {
    display: block;
    position: relative;
    width: 100%;
    flex: 60%;

    .map {
      width: 60% !important;
    }
  }

  #main {
    flex: 40%;

    header { 
      .title {
        font-size: 2.8rem;
      }

      .btn-menu {
        font-size: 1.85rem;
      }
    }
  }

}

@media screen and (min-width: 1150px) {
  #detail {
    flex: 62%;

    .map {
      width: 62% !important;
    }
  }

  #main {
    flex: 38%;
  }
}

@media screen and (min-width: 1250px) {
  #detail {
    flex: 66%;

    .map {
      width: 66% !important;
    }
  }

  #main {
    flex: 34%;
  }
}

@media screen and (min-width: 1500px) {
  #detail {
    flex: 70%;

    .map {
      width: 70% !important;
    }

    .detail-content {
      padding: 2.4rem;
    }
  }

  #main {
    flex: 30%;
    padding: 2.4rem;

    header {
      .title {
        font-size: 3.2rem;
      }

      .btn-menu {
        font-size: 2.15rem;
      }
    }
  }
}