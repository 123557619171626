.pac-container {
  z-index: 1051 !important;
}

.pac-item {
  padding: 0.5rem 1rem;
}

.pac-icon {
  display: none;
}