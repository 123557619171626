@import "../variables.scss";
@import "../mixins.scss";

$duration: 6s;

.alert-box {
  margin-bottom: 3rem;

  .alert.alert-with-slideout {
    padding-bottom: 1rem;
    border: 0;
    animation-duration: $duration;

    &:after {
      @extend .rounded;
      content: '';
      display: block;
      background: white;
      opacity: .5;
      width: 96%;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 2px;
      animation-duration: $duration;
      animation-name: slideout;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      @keyframes slideout {
        0% {width: 96%;}
        100% { width: 0%;}
      }
    }

    &:hover:after {
      animation-play-state: paused;
    }
    
  }
}