$font-display: swap;
$line-height-base: 1.7;
$font-size-base: 1.03rem;
$enable-responsive-font-sizes: true;

@import '~bootstrap/scss/bootstrap.scss';

h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

hr {
  border-top: 1px solid $body-bg-3;
}

.btn.btn-secondary:hover {
  border: 1px solid $body-bg-3;
  background-color: $body-bg-3;
}

.text-darker {
  color: $body-color-darker;
}

.badge {
  margin-left: .2rem;
  vertical-align:middle;
  margin-top: -.3em;
}

.badge-light {
  background-color: $body-color-darker;
}

svg.icon {
  margin-top: -.2em;
}

.alert-primary, .alert-success {
  color: $body-color;
  background-color: theme-color("primary");
  border: 0;
}

.alert-warning {
  color: $body-bg;
  background-color: theme-color("warning");
  border: 0;
}

.alert-danger {
  color: $body-color;
  background-color: theme-color("danger");
  border: 0;
}

.modal-content {
  color: $body-bg;

  .modal-header,
  .modal-footer {
    border: 0;
  }
}

ul, ol {
  list-style: none;
  padding: 0;
}

.bg-body {
  background-color: $body-bg;
}

.bg-danger {
  background-color: theme-color("danger");
}