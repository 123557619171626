@import "../variables.scss";

.gallery {
  position: fixed;
  background-color: $body-bg;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 300;
  text-align: center;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.transparentBackground {
    background-color: rgba(39, 41, 61, 0.9);
  }
  
  .galleryHeader {
    background-color: $body-bg;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .imageContainer {
    margin: 0 auto;
    width: 100%;
    height: 90%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}